<template>
    <div id="data-view" class ="curContainer">
        大区+区域、省份、城市
        车站等级
        媒体等级
        城市景点
        <!-- <el-upload action="#" list-type="picture-card" show-file-list limit=2 :auto-upload="false"
            multiple :on-exceed="fileExceed" :on-preview="filePreview">
            <i slot="default" class="el-icon-plus"></i>
            <div style="display:flex;">
            <div type="flex" v-for="(file,index) in files" :key="index">
                <div slot="file" slot-scope="{file}">
                    <img class="el-upload-list__item-thumbnail" :src="file.url" alt="">
                    <span class="el-upload-list__item-actions">
                        <span class="el-upload-list__item-preview" @click="handlePictureCardPreview(file)">
                            <i class="el-icon-zoom-in"></i>
                        </span>
                        <span v-if="!disabled" class="el-upload-list__item-delete" @click="handleDownload(file)">
                            <i class="el-icon-download"></i>
                        </span>
                        <span v-if="!disabled" class="el-upload-list__item-delete" @click="handleRemove(file)">
                            <i class="el-icon-delete"></i>
                        </span>
                    </span>
                </div>
            </div>
            </div>
              <div slot="tip" class="el-upload__tip">只能上传jpg/png文件，且不超过2个文件</div>
        </el-upload> -->
          <el-upload
            ref="upload"
            :class="{hide:hideUpload}"
            drag
            action="#"
            :multiple="false"
            list-type="picture-card"
            accept=".jpg, .png"
            :show-file-list="true"
            :auto-upload="false"
            :limit="2"
            :file-list="files"
            :on-remove="removeFile"
            :on-change="handleChange"
          >
            <i slot="default" class="el-icon-plus" />
            <div slot="file" slot-scope="{file}">
              <img class="el-upload-list__item-thumbnail" :src="file.url" alt="">
              <span class="el-upload-list__item-actions">
                <span
                  v-if="!disabled"
                  class="el-upload-list__item-delete"
                  @click="handleRemove(file, fileList)"
                >
                  <i class="el-icon-delete" />
                </span>
              </span>
            </div>
          </el-upload>
            <!-- <el-dialog :visible.sync="dialogImg">
            <img width="100%" :src="dialogImageUrl" alt="">
          </el-dialog> -->
        
      <!-- <el-form inline :model="searchForm" label-width="140px" style="margin-top: 30px;">
        <el-form-item label="选择站点">
            <el-cascader
                :options="areaStationList"
                :show-all-levels="false"    
                :props="props"
                v-model="searchForm.stationArray"
                collapse-tags               
                clearable></el-cascader>
        </el-form-item>
        <el-form-item label="关键字">
            <el-input v-model="searchForm.key" autocomplete="off"></el-input>
        </el-form-item>
        <el-button @click="onSearch" type="primary">搜索</el-button>
      </el-form> -->

    </div>
    <el-dialog v-model="dialogVisible">
        <img style="width:100%;" :src="dialogImageUrl" alt="">
    </el-dialog>
</template>


<script>
import CommonTable from "@/components/CommonTable";
import { mapGetters } from "vuex";
import MyUploadFileFormItem from "@/components/MyUploadFileFormItem";

export default {
    components: {
        // vueQr
    },
    components: { CommonTable,MyUploadFileFormItem}, 
    computed: { ...mapGetters(["areaStationList"]) },

    data() {
        return {
            searchForm:{
                stationArray:[],
                key:'',
            },
            file:{url:'https://gimg3.baidu.com/search/src=http%3A%2F%2Fpics5.baidu.com%2Ffeed%2F9345d688d43f8794ff3b50b241bebbfe19d53a91.jpeg%3Ftoken%3D185a69b92d2f2cae9d21a84777b43077&refer=http%3A%2F%2Fwww.baidu.com&app=2021&size=f360,240&n=0&g=0n&q=75&fmt=auto?sec=1659546000&t=8ca25a4c8aaeb8a56c139bd0bd2aa4fe'},
            files:[
                {url:'https://gimg3.baidu.com/search/src=http%3A%2F%2Fpics5.baidu.com%2Ffeed%2F9345d688d43f8794ff3b50b241bebbfe19d53a91.jpeg%3Ftoken%3D185a69b92d2f2cae9d21a84777b43077&refer=http%3A%2F%2Fwww.baidu.com&app=2021&size=f360,240&n=0&g=0n&q=75&fmt=auto?sec=1659546000&t=8ca25a4c8aaeb8a56c139bd0bd2aa4fe'}
                ,{url:'https://gimg3.baidu.com/search/src=http%3A%2F%2Fpics3.baidu.com%2Ffeed%2F7c1ed21b0ef41bd52bbc7201b73c3bc13bdb3d4d.jpeg%3Ftoken%3D135c1f7f3d14f6e672054f8713ee540f&refer=http%3A%2F%2Fwww.baidu.com&app=2021&size=f360,240&n=0&g=0n&q=75&fmt=auto?sec=1659546000&t=d7c6d4008771afe460fe76d3d70f941d'}
                ,{url:'https://gimg3.baidu.com/search/src=http%3A%2F%2Fpics1.baidu.com%2Ffeed%2F3b87e950352ac65cbd60221b7dee0e1b93138a1c.jpeg%3Ftoken%3D59fa330a51f782b458ae3142121dd69e&refer=http%3A%2F%2Fwww.baidu.com&app=2021&size=f360,240&n=0&g=0n&q=75&fmt=auto?sec=1659546000&t=c9ac61925eecc474f222ed66249d39ee'}
            ],
            dialogImageUrl: '',
            dialogVisible: false,
            disabled: false,

        };
    },
    watch: {},
    $route: {

    },
    async mounted(){

    },

    methods: {
      fileExceed(){
        this.$message.warning("文件超出2个限制")
      },

      filePreview(file){
        console.log('file',file)
        this.dialogImageUrl = file.url;
        this.dialogVisible = true;
      }
       
    }
};
</script>

<style lang="less">
    // @import url("//unpkg.com/element-ui@2.15.7/lib/theme-chalk/index.css");
  .curContainer {
    /* padding: 30px; */
    background: #fff;
    border: 1px solid #ddd;
    border-radius: 5px;
}
// .uploadSing {

//   .avatar-uploader .el-upload {

//     border: 1px dashed #d9d9d9;

//     border-radius: 6px;

//     cursor: pointer;

//     position: relative;

//     overflow: hidden;

//   }

//   .avatar-uploader .el-upload:hover {

//     border-color: #409EFF;

//   }

//   .avatar-uploader-icon,.el-upload--picture-card {

//     font-size: 28px;

//     color: #8c939d;

//     width: 80px;

//     height: 80px;

//     line-height: 80px;

//     text-align: center;

//   }

//   .avatar {

//     width: 80px;

//     height: 80px;

//     display: block;

//   }

//   .el-upload-list--picture-card .el-upload-list__item {

//       width: 80px;

//       height: 80px;

//   }

//   .el-upload__tip {

//     text-align: center;

//   }

// .uoloadSty .el-upload--picture-card{

//     font-size: 28px;

//     color: #8c939d;

//     width: 80px;

//     height: 80px;

//     line-height: 80px;

//     text-align: center;

//   }

//   .disUoloadSty .el-upload--picture-card{

//     display: none;   /* 上传按钮隐藏 */

//   }

  .el-upload-list__item-actions {

    width: 80px;

    height: 80px;

    position: absolute;

    top: 0;

    display: flex;

    align-items: center;

    justify-content: space-around;

    visibility: hidden;

    background: rgba(0, 0, 0, 0.4);

  }

//   .el-upload-list__item-preview {

//     display: inline-block;

//     width: 20px;

//     height: 20px;

//     display: flex;

//     align-items: center;

//     justify-content: center;

//   }

//   .el-icon-zoom-in:before,

//   .el-icon-delete:before {

//     font-size: 30px;

//     color: #fff;

//   }

//   .el-upload:hover .el-upload-list__item-actions {

//     visibility: visible;

//     transition: 1s;

//     z-index: 200;

//   }

</style>